import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import i18n_keys from "i18n_keys"
import { AppConfiguration } from "config"


const language = localStorage.getItem("I18N_LANGUAGE")
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "en")
}
i18n
  .use(detector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          [i18n_keys.NO_EVENTS_TO_DISPLAY]: 'No events to display',
          [i18n_keys.EMAIL_REQUIRED]: 'Please Enter Your Email',
          [i18n_keys.PASSWORD_REQUIRED]: 'Please Enter Your Password',
          [i18n_keys.FIRST_NAME_REQUIRED]: 'Please Enter Your First Name',
          [i18n_keys.LAST_NAME_REQUIRED]: 'Please Enter Your Last Name',
          [i18n_keys.STATE_REQUIRED]: 'Please Enter Your State',
          [i18n_keys.PHONE_REQUIRED]: 'Please Enter Your Phone',
          [i18n_keys.COMPANY_NAME_REQUIRED]: 'Please Enter Your Company Name',
          [i18n_keys.ADDRESS_REQUIRED]: 'Please Enter Your Address',
          [i18n_keys.CITY_REQUIRED]: 'Please Enter Your City',
          [i18n_keys.ZIP_CODE_REQUIRED]: 'Please Enter Your Zip Code',
          [i18n_keys.USER_MANAGEMENT]: 'User Management',
          [i18n_keys.CERTIFICATE_VAULT]: 'Certificate Vault',
          [i18n_keys.NO_USERS_TO_DISPLAY]: "You currently don't have any users",
          [i18n_keys.NO_CERTIFICATES_TO_DISPLAY]: "You currently don't have any certificates",
          [i18n_keys.REGISTRATION_SUCCESSFUL_MESSAGE]: "Registration successful, please login to proceed",
          [i18n_keys.DASHBOARD_ACTIVITY_BY_STATE]: "Activity by State",
          [i18n_keys.SESSIONS]: 'Sessions',
          [i18n_keys.AVG_DURATION]: 'Avg Duration',
          [i18n_keys.MOBILE]: 'Mobile',
          [i18n_keys.DESKTOP]: 'Desktop',
          [i18n_keys.TABLET]: 'Tablet',
          [i18n_keys.NO_CAMPAIGNS]: 'You currently don\'t have any campaigns',
          [i18n_keys.CREATE_PASSWORD]: 'Create a password',
          [i18n_keys.ENTER_NEW_PASSWORD]: 'Enter New Password',
          [i18n_keys.RE_ENTER_NEW_PASSWORD]: 'Re-Enter New Password',
          [i18n_keys.CREATE_NEW_PASSWORD_TO_PROCEED]: 'Create new password to proceed further.',
          [i18n_keys.GET_FREE_CONSENT_CONFIRMATION_ACCOUNT]: 'Get your free Consent Confirmation account.',
          [i18n_keys.CAMPAIGN_NAME_TOOLTIP_MESSAGE]: 'Name of campaign',
          [i18n_keys.CAMPAIGN_DATE_TOOLTIP_MESSAGE]: 'Date of when campaign was created',
          [i18n_keys.CERTIFICATE_TOOLTIP_MESSAGE]: ' Number of recordings & certificates generated',
          [i18n_keys.CREATE_CAMPAIGN_TOOLTIP_MESSAGE]: 'Create a name for your campaign',
          [i18n_keys.RETAIN_CERTIFICATE_FOR_TOOLTIP_MESSAGE]: 'The amount of time that we will retain your certificates & session recordings',
          [i18n_keys.CERTIFICATE_ID_TOOLTIP_MESSAGE]: 'Unique ID generated for this session',
          [i18n_keys.DATE_TOOLTIP_MESSAGE]: 'Date of session took place',
          [i18n_keys.DURATION_TOOLTIP_MESSAGE]: 'Time visitor spent on form',
          [i18n_keys.PAGE_URL_TOOLTIP_MESSAGE]: 'Page user visted during the session',
          [i18n_keys.IP_ADDRESS_TOOLTIP_MESSAGE]: 'User IP address',
          [i18n_keys.LOCATION_TOOLTIP_MESSAGE]: 'User location',
          [i18n_keys.BROWSER_TOOLTIP_MESSAGE]: 'Browser and operating system visitor used',
          [i18n_keys.AVG_DURATION_TOOLTIP_MESSAGE]: 'Average time visitor spent on form',
          [i18n_keys.SESSIONS_TOOLTIP_MESSAGE]: 'Number of recordings & certificates generated',
          [i18n_keys.DEVICE_TYPE_TOOLTIP_MESSAGE]: 'Top devices visitors used to access your form',
          [i18n_keys.TOP_BROWSERS_TOOLTIP_MESSAGE]: 'Top browsers visitors used to access your form',
          [i18n_keys.TOP_DOMAINS_TOOLTIP_MESSAGE]: 'Top domains visitors accessed',
          [i18n_keys.TOP_STATES_TOOLTIP_MESSAGE]: 'List of states where your visitors are coming from',
          [i18n_keys.PAGE_TITLE_SUFFIX]: ` | ${AppConfiguration.WEBSITE}`,
          [i18n_keys.COPY_CERTIFICATE_URLTOOLTIP_MESSAGE]: 'Use this link to share the certificate and session recording with anyone',
          [i18n_keys.LAST_SIGN_IN_TOOLTIP_MESSAGE]: "The last time this user logged in to their account",
          [i18n_keys.SIGN_IN_COUNT_TOOLTIP_MESSAGE]: "Number of times this user has logged in to their account",
          [i18n_keys.DASHBOARD_ACTIVITY_BY_STATE_TOOLTIP_MESSAGE]: 'States where your visitors are coming from',
          [i18n_keys.FORM_COMPLETED_TOOLTIP_MESSAGE]: 'The user completed the form',
          [i18n_keys.COMPLETED_CERTIFICATE_TOOTIP_MESSAGE]: 'Number of recordings & certificates completed',
          [i18n_keys.CONSENT_CAPTURED_TOOLTIP_MESSAGE]: 'List of Insurance Carriers Checked',
          [i18n_keys.TCPA_DISCLAIMER_TOOLTIP_MESSAGE]: 'TCPA Disclaimer text',
          [i18n_keys.CONSENT_CAPTURES_TOOLTIP]: 'User has given the consent'
        }
      },
      de: {
        translation: {
          [i18n_keys.NO_EVENTS_TO_DISPLAY]: 'Keine Ereignisse zum Anzeigen'
        }
      }
    }
  });

export default i18n
