import { BROWSER_TYPES, COMPARISON_FIELD_OPTIONS, FILTER_COMPONENT_TYPES, STATUS_TYPES, YES_AND_NO } from '../constants';
import { us_states } from "../../components/common/data/us_states";


export const PROSPECT_FILTERED_COLUMNS_NAMES = {
    CAMPAIGN_NAME: "campaign_name",
    CERTIFICATE_ID: "certificate_id",
    DURATION: "duration",
    PAGE_URL: "page_url",
    IP_ADDRESS: "ip_address",
    LOCATION: "state",
    BROWSER: "browser",
    DATE: "date",
    COMPLETED: "completed",
    AID: "aid",
    CID: "cid",
    CLIENT_NAME: "client_name",
    DEVICE_TYPE: 'device_type',
    INSURANCE_CARRIER: 'insurance_carrier',
    CONSENT_CAPTURED: 'consent_captured'
}

export const CALL_STATUS_OPTIONS = [
    { value: STATUS_TYPES.BUSY, label: "Busy" },
    { value: STATUS_TYPES.FAILED, label: "Failed" },
    { value: STATUS_TYPES.COMPLETED, label: "Completed" },
    { value: STATUS_TYPES.IN_PROGRESS, label: "In Progress" },
    { value: STATUS_TYPES.NO_ANSWER, label: "No Answer" },
]

export const COMPLETED_OPTIONS = [
    { value: YES_AND_NO.YES, label: "Yes" },
    { value: YES_AND_NO.NO, label: "No" },
]

export const getProspectFilteredColumnsObjects = (browser_options, states_options, insurance_carrier_options) => ({
    [PROSPECT_FILTERED_COLUMNS_NAMES.DATE]: {
        order: 1,
        comparator_value: "",
        value: "",
        label: "Date",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.DATE, // "date",
        component_type: FILTER_COMPONENT_TYPES.DATE_RANGE
    },

    [PROSPECT_FILTERED_COLUMNS_NAMES.CERTIFICATE_ID]: {
        order: 3,
        comparator_value: "",
        value: "",
        label: "Certificate ID",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.CERTIFICATE_ID, // "certificate_id",
        component_type: FILTER_COMPONENT_TYPES.TEXT
    },
    [PROSPECT_FILTERED_COLUMNS_NAMES.COMPLETED]: {
        order: 4,
        comparator_value: "",
        value: "",
        label: "Form Completed",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.COMPLETED, // "completed",
        component_type: FILTER_COMPONENT_TYPES.SINGLE_SELECT,
        options: COMPLETED_OPTIONS
    },

    [PROSPECT_FILTERED_COLUMNS_NAMES.PAGE_URL]: {
        order: 6,
        comparator_value: "",
        value: "",
        label: "Page URL",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.PAGE_URL, // "page_url",
        component_type: FILTER_COMPONENT_TYPES.TEXT
    },

    [PROSPECT_FILTERED_COLUMNS_NAMES.BROWSER]: {
        order: 9,
        comparator_value: "",
        value: "",
        label: "Browser & OS",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.BROWSER, // "browser",
        component_type: FILTER_COMPONENT_TYPES.MULTI_SELECT,
        options: getBrowserOptions(browser_options) || []
    },

    [PROSPECT_FILTERED_COLUMNS_NAMES.IP_ADDRESS]: {
        order: 7,
        comparator_value: "",
        value: "",
        label: "IP Address",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.IP_ADDRESS, // "ip_address",
        component_type: FILTER_COMPONENT_TYPES.TEXT
    },

    [PROSPECT_FILTERED_COLUMNS_NAMES.CAMPAIGN_NAME]: {
        order: 2,
        comparator_value: "",
        value: "",
        label: "Campaign",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.CAMPAIGN_NAME, // "campaign_name",
        component_type: FILTER_COMPONENT_TYPES.MULTI_SELECT
    },

    [PROSPECT_FILTERED_COLUMNS_NAMES.LOCATION]: {
        order: 8,
        comparator_value: "",
        value: "",
        label: "State",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.LOCATION, // "state",
        component_type: FILTER_COMPONENT_TYPES.MULTI_SELECT,
        options: getStatesOptions(states_options) || []
    },

    // Device type filter to be added later
    // [PROSPECT_FILTERED_COLUMNS_NAMES.DEVICE_TYPE]: { 
    //     order: 13,
    //     comparator_value: "",
    //     value: "",
    //     label: "Device Types",
    //     name: PROSPECT_FILTERED_COLUMNS_NAMES.DEVICE_TYPE, // "device types",
    //     component_type: FILTER_COMPONENT_TYPES.MULTI_SELECT,
    //     options: getDeviceTypeOptions(device_types) || []
    // },
    [PROSPECT_FILTERED_COLUMNS_NAMES.DURATION]: {
        order: 5,
        comparator_value: "",
        value: "",
        label: "Duration",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.DURATION, // "Call Duration",
        component_type: FILTER_COMPONENT_TYPES.COMPARATOR,
        options: COMPARISON_FIELD_OPTIONS,
        suffix: " sec"
    },

    [PROSPECT_FILTERED_COLUMNS_NAMES.AID]: {
        order: 10,
        comparator_value: "",
        value: "",
        label: "AID",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.AID, // "SId",
        component_type: FILTER_COMPONENT_TYPES.TEXT
    },

    [PROSPECT_FILTERED_COLUMNS_NAMES.CID]: {
        order: 11,
        comparator_value: "",
        value: "",
        label: "CID",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.CID, // "Cid",
        component_type: FILTER_COMPONENT_TYPES.TEXT
    },

    [PROSPECT_FILTERED_COLUMNS_NAMES.CLIENT_NAME]: {
        order: 12,
        comparator_value: "",
        value: "",
        label: "Client Name",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.CLIENT_NAME, // "client_name",
        component_type: FILTER_COMPONENT_TYPES.SINGLE_SELECT
    },

    [PROSPECT_FILTERED_COLUMNS_NAMES.INSURANCE_CARRIER]: {
        order: 13,
        comparator_value: "",
        value: "",
        label: "Insurance Carrier",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.INSURANCE_CARRIER, // "insurance carriers",
        component_type: FILTER_COMPONENT_TYPES.MULTI_SELECT,
        options: getInsurenceCarrierOptions(insurance_carrier_options) || []
    },

    [PROSPECT_FILTERED_COLUMNS_NAMES.CONSENT_CAPTURED]: {
        order: 14,
        comparator_value: "",
        value: "",
        label: "Consent Captured",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.CONSENT_CAPTURED, // "consent captured",
        component_type: FILTER_COMPONENT_TYPES.SINGLE_SELECT,
        options: COMPLETED_OPTIONS
    },
})

export const getStatesOptions = (states_options) => {
    return states_options.map(states => ({
        value: states,
        label: states
    }));
};

export const getBrowserOptions = (browsers) => {
    return browsers.map(browser => ({
        value: browser.toLowerCase(),
        label: browser.charAt(0).toUpperCase() + browser.slice(1)
    }));
};

export const getInsurenceCarrierOptions = (insurance_carriers) => {
    return insurance_carriers.map(insurance_carrier => ({
        value: insurance_carrier,
        label: insurance_carrier
    }));
}


// export const getDeviceTypeOptions = (device_types) => {
//     return device_types.map(device_type => ({
//         value: device_type,
//         label: device_type
//     }));
// }
